<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2 text-warning">Exercices Comptables</h6>
    </b-row>
    <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
      <b-thead head-variant="light">
        <b-tr>
          <b-th>Exercice</b-th>
          <b-th>Début</b-th>
          <b-th>Fin</b-th>
          <b-th>Statut</b-th>
          <b-th>Actions</b-th>
        </b-tr>
      </b-thead>
      <b-tbody
        v-for="(exercice, exercice_index) in exerciceList"
        :key="exercice_index"
      >
        <b-tr>
          <b-td>{{ exercice.period }}</b-td>
          <b-td variant="success">
            {{ exercice.datestart }}
          </b-td>
          <b-td variant="warning">
            {{ exercice.dateend }}
          </b-td>
          <b-td>
            <span v-if="exercice.status == 0"> En Attente </span>
            <span v-if="exercice.status == 1"> Confirmé </span>
            <span v-if="exercice.status == 2"> En Cours </span>
            <span v-if="exercice.status == 3"> Cloturé </span>
            <span v-if="exercice.status == 4"> Annulé </span>
            <span v-if="exercice.status == 5"> Suspendu </span>
          </b-td>

          <b-td variant="">
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="align-middle text-body"
              variant="info"
              @click="
                $router.push({
                  name: 'accountingfile-view',
                  params: { id: exercice.id },
                })
              "
            />

            |
            <feather-icon
              icon="EditIcon"
              size="16"
              class="align-middle text-body"
              variant="success"
              @click="
                $router.push({
                  name: 'accountingfile-edit',
                  params: { id: exercice.id },
                })
              "
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BCard,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BRow,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BRow,
  },
  directives: {
    Ripple,
  },
  props: {
    exerciceData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      exerciceList: [],
      errors: [],
    };
  },
  async mounted() {
    // 
    // accountingfile
    await myApi
      .get(`accountingfile`)
      .then((response) => {
        var data = response.data.data;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.exerciceList.push({
            id: data[i].id,
            period: data[i].period,
            datestart: data[i].datestart,
            dateend: data[i].dateend,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  methods: {},
};
</script>
